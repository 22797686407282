@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?54d2f86452a24b0a7d2c614d75a68ae9?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?54d2f86452a24b0a7d2c614d75a68ae9") format("woff2"),
url("./cy-custom-icons.woff?54d2f86452a24b0a7d2c614d75a68ae9") format("woff"),
url("./cy-custom-icons.ttf?54d2f86452a24b0a7d2c614d75a68ae9") format("truetype"),
url("./cy-custom-icons.svg?54d2f86452a24b0a7d2c614d75a68ae9#cy-custom-icons") format("svg");
}

i.icon.band_aid,
i.icon.capsules,
i.icon.comments-regular,
i.icon.cy,
i.icon.eye_dropper,
i.icon.eye-slash-solid,
i.icon.eye-solid,
i.icon.hand_holding_water,
i.icon.layers,
i.icon.linear-scale,
i.icon.lock2,
i.icon.material-chain,
i.icon.media,
i.icon.numeric-input,
i.icon.open-lock2,
i.icon.prescription_bottle,
i.icon.published,
i.icon.question_alternative,
i.icon.smile-0,
i.icon.smile-1,
i.icon.smile-2,
i.icon.smile-3,
i.icon.smile-4,
i.icon.special_smr,
i.icon.spray_can,
i.icon.syringe,
i.icon.tablets,
i.icon.tasks,
i.icon.tint,
i.icon.unpublished,
i.icon.useredit,
i.icon.users-slash-solid,
i.icon.users,
i.icon.yes-no {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.band_aid:before {
    content: "\f101";
}
i.icon.capsules:before {
    content: "\f102";
}
i.icon.comments-regular:before {
    content: "\f103";
}
i.icon.cy:before {
    content: "\f104";
}
i.icon.eye_dropper:before {
    content: "\f105";
}
i.icon.eye-slash-solid:before {
    content: "\f106";
}
i.icon.eye-solid:before {
    content: "\f107";
}
i.icon.hand_holding_water:before {
    content: "\f108";
}
i.icon.layers:before {
    content: "\f109";
}
i.icon.linear-scale:before {
    content: "\f10a";
}
i.icon.lock2:before {
    content: "\f10b";
}
i.icon.material-chain:before {
    content: "\f10c";
}
i.icon.media:before {
    content: "\f10d";
}
i.icon.numeric-input:before {
    content: "\f10e";
}
i.icon.open-lock2:before {
    content: "\f10f";
}
i.icon.prescription_bottle:before {
    content: "\f110";
}
i.icon.published:before {
    content: "\f111";
}
i.icon.question_alternative:before {
    content: "\f112";
}
i.icon.smile-0:before {
    content: "\f113";
}
i.icon.smile-1:before {
    content: "\f114";
}
i.icon.smile-2:before {
    content: "\f115";
}
i.icon.smile-3:before {
    content: "\f116";
}
i.icon.smile-4:before {
    content: "\f117";
}
i.icon.special_smr:before {
    content: "\f118";
}
i.icon.spray_can:before {
    content: "\f119";
}
i.icon.syringe:before {
    content: "\f11a";
}
i.icon.tablets:before {
    content: "\f11b";
}
i.icon.tasks:before {
    content: "\f11c";
}
i.icon.tint:before {
    content: "\f11d";
}
i.icon.unpublished:before {
    content: "\f11e";
}
i.icon.useredit:before {
    content: "\f11f";
}
i.icon.users-slash-solid:before {
    content: "\f120";
}
i.icon.users:before {
    content: "\f121";
}
i.icon.yes-no:before {
    content: "\f122";
}
