/*******************************
     User Global Variables
*******************************/
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #bee0e7;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #4eacbe;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #458794;
}
